import React from "react";
import { ArrowForward } from "@mui/icons-material";
import { useQuery } from "@apollo/client";
import { GETNEWS } from "../utils";
import { Link } from "react-router-dom";
import Loading from "../Loading";
import Slider from "react-slick";
import { format, parseISO } from "date-fns";
import Navbar from "../Navbar";

const News = () => {
  const { data, loading, error } = useQuery(GETNEWS);

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    cssEase: "linear",
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    appendDots: (dots) => (
      <div>
        <ul
          style={{
            width: "20rem",
            display: "flex",
            justifyContent: "space-evenly",
            padding: "10px",
            marginBottom: "-50px",
          }}
        >
          {dots}
        </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: "30px",
          color: "#e7c725",
          borderRadius: "20%",
          marginRight: "10px",
          display: "flex",
          justifyContent: "space-evenly",
          border: "1px #e7c725 solid",
        }}
      >
        {i + 1}
      </div>
    ),
  };

  if (loading) return <Loading />;
  if (error) return `Error! ${error.message}`;

  return (
    <>
    <Navbar/>
     <div className="w-3/4 mx-auto mt-24 mb-16 max-lg:mb-24 max-lg:ml-4 max-lg:w-11/12">
      <h2 className="text-3xl font-light max-lg:text-2xl max-lg:font-bold my-10">
        Latest news and happenings from
        <span className="text-wageyellow ml-2">MyWagePay</span>
      </h2>
      <Slider {...settings}>
        {data.newsPosts.map((item) => (
          <Link
            to={`/newspost/${item.slug}`}
            className="post-link"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <div className="flex flex-col w-11/12 mx-auto  justify-evenly h-[28rem] p-2 items-center  rounded hover:shadow-xl cursor-pointer mb-8 max-lg:flex-col">
              <div className="w-full h-64 mx-auto flex justify-center  max-lg:w-3/4 max-lg:mx-4">
                <img
                  src={item.coverImage.url}
                  alt={item.newsHeading}
                  className="rounded mt-0"
                />
              </div>
              <div className="w-full justify-evenly h-32 max-lg:h-fit flex flex-col items-center">
                <div className="w-full flex flex-col items-start  max-lg:mx-4 mx-auto">
                  <h2 className="font-bold capitalize text-lg mb-5 max-lg:text-lg ">
                    {item.newsHeading}
                  </h2>
                  <div className="flex justify-start w-full max-lg:hidden ">
                    <h6 className=" text-wageyellow mr-2 flex items-center">
                      Read More
                      <ArrowForward />
                    </h6>
                  </div>
                  <h6 className="font-normal text-slate-500 text-justify max-lg:text-left mt-3 w-full">
                    Date Published :
                    {format(parseISO(item.publishedAt ?? ""), "dd.MM.yyyy")}
                  </h6>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </Slider>
    </div>
    </>
   
  );
};

export default News;
