import React from "react";
import logo from "./Media/logo.jpg";
import { Link } from "react-router-dom";
import SegmentRoundedIcon from "@mui/icons-material/SegmentRounded";
import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Drawer } from "@mui/material";

const Navbar = () => {
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);

  const toggleDrawer = () => setOpenDrawer(!openDrawer);
  const handleChange = (panel) => (event, isExpanded) => setExpanded(isExpanded ? panel : false);

  const list = (
    <div className="flex flex-col mt-10 w-screen mx-auto font-semibold text-gray-800 cursor-pointer">
      <Link to="/whymywagepay" className="py-2 px-4 hover:text-wageyellow">Why MyWagePay</Link>
      <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")} className=" bg-transparent shadow-none ">
        <AccordionSummary expandIcon={<ExpandMore />} className="py-2 px-4 hover:text-wageyellow font-semibold ">Products</AccordionSummary>
        <AccordionDetails className="flex flex-col w-full">
          <Link to="/embed" className="py-1 px-4 hover:text-wageyellow">Embed</Link>
          <Link to="/capital" className="py-1 px-4 hover:text-wageyellow">Capital</Link>
          <Link to="/profiling" className="py-1 px-4 hover:text-wageyellow">Score Check</Link>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === "panel2"} onChange={handleChange("panel2")} className="border-0 bg-transparent shadow-none">
        <AccordionSummary expandIcon={<ExpandMore />} className="py-2 px-4 hover:text-wageyellow font-semibold">Company</AccordionSummary>
        <AccordionDetails className="flex flex-col w-full">
          <Link to="/aboutus" className="py-1 px-4 hover:text-wageyellow">About Us</Link>
          <Link to="/careers" className="py-1 px-4 hover:text-wageyellow">Careers</Link>
          <Link to="/register" className="py-1 px-4 hover:text-wageyellow">Contact Us</Link>
        </AccordionDetails>
      </Accordion>
      <Link to="/invest" className="py-2 px-4 hover:text-wageyellow">Invest</Link>
      <Accordion expanded={expanded === "panel3"} onChange={handleChange("panel3")} className="border-0 bg-transparent shadow-none">
        <AccordionSummary expandIcon={<ExpandMore />} className="py-2 px-4 hover:text-wageyellow font-semibold">Learn</AccordionSummary>
        <AccordionDetails className="flex flex-col w-full">
          <Link to="/news" className="py-1 px-4 hover:text-wageyellow">What's New</Link>
          <Link to="/blog" className="py-1 px-4 hover:text-wageyellow">Blog</Link>
        </AccordionDetails>
      </Accordion>
    </div>
  );

  return (
    <div>
      <div className="flex items-center justify-between fixed w-full top-0 h-16 px-10 bg-white border-b border-wageyellow shadow-md">
        <Link to="/" className="flex items-center">
          <img src={logo} alt="MyWagePay" className="w-12 h-12 mr-2" />
          <h1 className="text-lg font-bold text-gray-800">MyWagePay</h1>
        </Link>
        <div className="hidden lg:flex items-center space-x-8 font-semibold text-gray-800">
          <Link to="/whymywagepay" className="hover:text-wageyellow">Why MyWagePay</Link>
          <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")} className="bg-transparent shadow-none border-0" style={{boxSizing:0}}>
            <AccordionSummary expandIcon={<ExpandMore />} className="hover:text-wageyellow font-semibold ">Products</AccordionSummary>
            <AccordionDetails className="absolute bg-white rounded-md flex flex-col mt-2 w-full">
              <Link to="/embed" className="py-1 px-4 hover:text-wageyellow">Embed</Link>
              <Link to="/capital" className="py-1 px-4 hover:text-wageyellow">Capital</Link>
              <Link to="/profiling" className="py-1 px-4 hover:text-wageyellow">Score Check</Link>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === "panel2"} onChange={handleChange("panel2")} className="border-0 bg-transparent shadow-none">
            <AccordionSummary expandIcon={<ExpandMore />} className="hover:text-wageyellow font-semibold ">Company</AccordionSummary>
            <AccordionDetails className="absolute bg-white rounded-md flex flex-col mt-2 w-full">
              <Link to="/aboutus" className="py-1 px-4 hover:text-wageyellow">About Us</Link>
              <Link to="/careers" className="py-1 px-4 hover:text-wageyellow">Careers</Link>
              <Link to="/register" className="py-1 px-4 hover:text-wageyellow">Contact Us</Link>
            </AccordionDetails>
          </Accordion>
          <Link to="/invest" className="hover:text-wageyellow">Invest</Link>
          <Accordion expanded={expanded === "panel3"} onChange={handleChange("panel3")} className="border-0 bg-transparent shadow-none">
            <AccordionSummary expandIcon={<ExpandMore />} className="hover:text-wageyellow font-semibold">Learn</AccordionSummary>
            <AccordionDetails className="absolute bg-white rounded-md flex flex-col mt-2 w-full">
              <Link to="/news" className="py-1 px-4 hover:text-wageyellow">What's New</Link>
              <Link to="/blog" className="py-1 px-4 hover:text-wageyellow">Blog</Link>
            </AccordionDetails>
          </Accordion>
        </div>
        <div className="lg:hidden flex items-center">
          <SegmentRoundedIcon onClick={toggleDrawer} className="cursor-pointer text-gray-800" />
        </div>
      </div>
      <Drawer open={openDrawer} anchor="top" onClose={toggleDrawer}>
        {list}
      </Drawer>
    </div>
  );
};

export default Navbar;
